@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Morfa';
  font-family: 'Nebulica';
  src: url('/public/Fonts/Morfa.ttf') format('woff2'),
       url('/public/Fonts/Nebulica-Bold.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}

.bg-glass {
  background: rgba(255, 255, 255, 0.30);
  box-shadow: 0 8px 32px 0 rgba(122, 123, 146, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
body{
  font-family:"Nebulica";

}
.blob-1{
  background-image: url(./Assests/crowd-partying-stage-lights-live-concert-summer-mu-2023-11-27-04-52-51-utc.jpg);
  background-color: #fff;
  width: 450px;
  height: 350px;
  background-size: cover;
  -webkit-background-position: center;
  background-position: center center;
  margin: 20px;
  box-shadow: 0 5px 5px 5px rgba(133, 253, 13, 0.2);
  animation: animate 5s ease-in-out infinite;
  transition: all 1s ease-in-out;
}
.blob-2{
  background-image: url(./Assests/crowd-at-a-concert-2023-11-27-05-34-15-utc.jpg);
  background-color: #fff;
  width: 450px;
  height: 350px;
  background-size: cover;
  -webkit-background-position: center;
  background-position: center center;
  margin: 20px;
  box-shadow: 0 5px 5px 5px rgba(57, 13, 253, 0.2);
  animation: animate 5s ease-in-out infinite;
  transition: all 1s ease-in-out;
}

@keyframes animate {
  0%, 100%{
      border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50%{
      border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
}
@media (max-width:450px) {
  .blob-1, .blob-2{
    width: 330px;
  height: 230px;
  }
}

/* modal.css */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 12px;
  border-radius: 8px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-arrow.service-next,
.slick-arrow.service-prev {
  background-color: #1842b6;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 1;
  z-index: 10;
  position: absolute;
  top: -60px;
}

.slick-arrow.service-next {
  right: 1%;
}

.slick-arrow.service-prev {
  left: 90%;
}

@media (max-width: 767px) {
  .slick-arrow.service-next {
    top: 55%;
    right: -20px;
   
  }
  .slick-arrow.service-prev {
    top: 55%;
    left: -20px;
   
  }
}

@media (max-width: 430px) {
  .slick-arrow.service-next {
    top: 55%;
    right: 2px;
   
  }
  .slick-arrow.service-prev {
    top: 55%;
    left: 2px;
   
  }
}
.slick-arrow.service-next:hover,
.slick-arrow.service-prev:hover {
  background-color: #9aca3c;
  opacity: 1;
}

.service-next::before, 
.service-prev::before {
  display: none;
}

.arrows {
  height: 25px;
  width: 25px;
}
.team-profile-blob {
  background-color: #fff;
  width: 300px;
  height: 280px;
  background-size: cover;
  -webkit-background-position: center;
  background-position: center center;
  margin: 20px;
  box-shadow: 0 5px 5px 5px rgba(233, 246, 201, 0.654);
  animation: teamblob 5s ease-in-out infinite;
  transition: all 1s ease-in-out;
}

@keyframes teamblob {
  0%, 100% {
    border-radius: 60% 40% 50% 30% / 30% 50% 50% 60%;
  }
  50% {
    border-radius: 55% 40% 30% 55% / 55% 30% 45% 45%;
  }
}
.gallery-img img{
  width: 300px;
  height: 180px;
}